import { createTickMarks } from "./createTickMarks";
import { dotsCreate } from "./dotsCreate";
import { dotsLoad } from "./dotsLoad";
export function init() {
    dotsLoad();
    dotsCreate();
    createTickMarks();
}
export function dummyTest() {
    return true;
}
document.addEventListener("DOMContentLoaded", init);
