import log from "./util.log";
export function dotsSave() {
    log('saveDots');
    // @ts-ignore
    const xyPlane = document.getElementById("xy-plane");
    // @ts-ignore
    const dots = xyPlane.getElementsByClassName("dot-container");
    const savedDots = [];
    for (let i = 0; i < dots.length; i++) {
        const dot = dots[i];
        savedDots.push({
            // @ts-ignore
            x: dot.style.left,
            // @ts-ignore
            y: dot.style.top,
            coordinates: dot.getElementsByClassName("dot-coordinates")[0].textContent,
            label: dot.getElementsByClassName("user-dot-label")[0].textContent,
        });
    }
    localStorage.setItem("dots", JSON.stringify(savedDots));
}
