import { tpf } from "./data";
import { dotsSave } from "./dotsSave";
import log from "./util.log";
export function mouseDown(event) {
    log('mouseDown');
    let dot = event.currentTarget;
    tpf.isDragging = true;
    tpf.currentDot = dot;
    tpf.offsetX = event.clientX - dot.offsetLeft;
    tpf.offsetY = event.clientY - dot.offsetTop;
}
export function mouseMove(event) {
    log('mouseMove');
    if (tpf.isDragging) {
        let newX = event.clientX - tpf.offsetX;
        let newY = event.clientY - tpf.offsetY;
        let newRoundedX = Math.round((newX - tpf.xAxisWidth / 2) / (tpf.xAxisWidth / 10) * 10) / 10;
        let newRoundedY = Math.round((tpf.yAxisHeight / 2 - newY) / (tpf.yAxisHeight / 10) * 10) / 10;
        if (tpf.currentDot != null && newRoundedX >= -5 && newRoundedX <= 5 && newRoundedY >= -5 && newRoundedY <= 5) {
            tpf.currentDot.style.left = (event.clientX - tpf.offsetX) + 'px';
            tpf.currentDot.style.top = (event.clientY - tpf.offsetY) + 'px';
        }
    }
}
export function mouseUp(event) {
    log('mouseUp');
    tpf.xAxisWidth = document.getElementsByClassName("x-axis")[0].clientWidth;
    tpf.yAxisHeight = document.getElementsByClassName("y-axis")[0].clientHeight;
    let dot = event.currentTarget;
    let innerLabel = dot.getElementsByClassName('dot-coordinates')[0];
    let innerRoundedX = Math.round((dot.offsetLeft - tpf.xAxisWidth / 2) / (tpf.xAxisWidth / 10) * 10) / 10;
    let innerRoundedY = Math.round((tpf.yAxisHeight / 2 - dot.offsetTop) / (tpf.yAxisHeight / 10) * 10) / 10;
    innerLabel.textContent = "(" + innerRoundedX.toFixed(1) + ", " + innerRoundedY.toFixed(1) + ")";
    tpf.isDragging = false;
    tpf.currentDot = null;
    tpf.skipGraphClick = true;
    dotsSave();
}
