let tpf = {
    skipGraphClick: false,
    isDragging: false,
    debug: true,
    offsetX: 0,
    offsetY: 0,
    currentDot: null,
    xAxisWidth: document.getElementsByClassName("x-axis")[0].clientWidth,
    yAxisHeight: document.getElementsByClassName("y-axis")[0].clientHeight,
};
export { tpf };
