import log from "./util.log";
export function createTickMarks() {
    log('createTickMarks');
    for (let i = -5; i <= 5; i++) {
        let tick = document.createElement('div');
        let axisClass = i === 0 ? "zero" : (i < 0 ? "negative" : "positive");
        tick.className = `x-tick ${axisClass}`;
        tick.style.left = (i + 5) * (100 / 11) + '%';
        // @ts-ignore
        document.getElementById('xy-plane').appendChild(tick);
        tick = document.createElement('div');
        tick.className = `y-tick ${axisClass}`;
        tick.style.top = (i + 5) * (100 / 11) + '%';
        // @ts-ignore
        document.getElementById('xy-plane').appendChild(tick);
    }
}
