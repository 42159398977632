import { tpf } from "./data";
import { dotsSave } from "./dotsSave";
import { removeAnyExistingElementsWithClassName } from "./removeAnyExistingElementsWithClassName";
import log from "./util.log";
//////////////////////createDots END///////////////////////////////////
// @ts-ignore
export function editDeleteMenu(dot) {
    log('editDeleteMenu');
    // Check if the mouse has been dragged
    if (tpf.isDragging) {
        return;
    }
    removeAnyExistingElementsWithClassName('edit-menu');
    // Get the current user-dot-label text
    let userLabelText = dot.getElementsByClassName("user-dot-label")[0].textContent;
    // Create the pop-up menu
    let editMenu = document.createElement("div");
    editMenu.classList.add("edit-menu");
    editMenu.innerHTML = `
		<label class="edit-menu-title">I want to...</label>
		<input type="text" value="${userLabelText}">
		<div class="edit-menu-buttons">
			<button class="save-button">Save</button>
			<button class="delete-button">Delete</button>
		</div>
	`;
    document.body.appendChild(editMenu);
    // Get the input and buttons
    let input = editMenu.getElementsByTagName("input")[0];
    let saveButton = editMenu.getElementsByClassName("save-button")[0];
    let deleteButton = editMenu.getElementsByClassName("delete-button")[0];
    // Highlight the input text
    input.select();
    // Add event listeners for the save and delete buttons
    saveButton.addEventListener("click", saveLabel);
    deleteButton.addEventListener("click", deleteDot);
    // Save label function
    function saveLabel() {
        log('saveLabel');
        // Get the new label text
        let newLabelText = input.value;
        // Update the dot's user-dot-label text
        dot.getElementsByClassName("user-dot-label")[0].textContent = newLabelText;
        // Remove the pop-up menu
        editMenu.remove();
        dotsSave();
    }
    // Delete dot function
    function deleteDot() {
        log('deleteDot');
        // Remove the dot and its labels
        dot.remove();
        // Remove the pop-up menu
        editMenu.remove();
    }
}
