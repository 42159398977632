import { tpf } from "./data";
import { editDeleteMenu } from "./editDeleteMenu";
import { removeAnyExistingElementsWithClassName } from "./removeAnyExistingElementsWithClassName";
import log from "./util.log";
import { mouseDown, mouseUp, mouseMove } from "./mouseEvents";
export function dotsLoad() {
    log('loadDots');
    removeAnyExistingElementsWithClassName('dot');
    // @ts-ignore
    const savedDots = JSON.parse(localStorage.getItem("dots"));
    if (savedDots) {
        for (let i = 0; i < savedDots.length; i++) {
            const savedDot = savedDots[i];
            const dot = addDot();
            dot.style.left = savedDot.x;
            dot.style.top = savedDot.y;
            dot.innerHTML = `
        <div class='dot'></div>
        <div class='coordinate-text'>
          <div class='user-dot-label' style='top:-6px'>${savedDot.label}</div>
          <div class='dot-coordinates'>${savedDot.coordinates}</div>
        </div>
      `;
            dot.addEventListener("contextmenu", mouseContextmenu);
            dot.addEventListener('mousedown', mouseDown);
            dot.addEventListener('mouseup', mouseUp);
            window.addEventListener('mousemove', mouseMove, false);
            // @ts-ignore
            document.getElementById("xy-plane").appendChild(dot);
        }
    }
    ;
}
function mouseContextmenu(event) {
    log('dot.contextmenu');
    event.preventDefault();
    removeAnyExistingElementsWithClassName('edit-menu');
    // @ts-ignore
    editDeleteMenu(getDotContainer(event.target));
    tpf.currentDot = null;
}
function addDot() {
    const dot = document.createElement("div");
    dot.classList.add("dot-container");
    return dot;
}
function getDotContainer(target) {
    if (target.className.includes('dot-container')) {
        return target;
    }
    else {
        // @ts-ignore
        return getDotContainer(target.parentElement);
    }
}
