import { tpf } from "./data";
import { editDeleteMenu } from "./editDeleteMenu";
import { removeAnyExistingElementsWithClassName } from "./removeAnyExistingElementsWithClassName";
import log from "./util.log";
import { mouseDown, mouseUp, mouseMove } from "./mouseEvents";
export function dotsCreate() {
    log("createDots");
    var xyPlane = document.getElementById("xy-plane");
    xyPlane === null || xyPlane === void 0 ? void 0 : xyPlane.addEventListener('click', xyPlaneClickHandler);
}
function xyPlaneClickHandler(event) {
    log('xyPlane.click');
    var graphRawCoords = getGraphRawCoordinates(event);
    var graphScaledCoords = getGraphScaledCoordinates(graphRawCoords);
    const savedDot = {
        x: graphRawCoords.x + 'px',
        y: graphRawCoords.y + 'px',
        coordinates: "(" + graphScaledCoords.x.toFixed(1) + ", " + graphScaledCoords.y.toFixed(1) + ")",
        label: 'null'
    };
    if (isClickInsideGraph(graphScaledCoords)) {
        log('isClickInsideGraph:branch');
        log(graphScaledCoords);
        if (tpf.currentDot === null && tpf.skipGraphClick === false) {
            const dot = addDot();
            dot.style.left = savedDot.x;
            dot.style.top = savedDot.y;
            dot.innerHTML = `
        <div class='dot'></div>
        <div class='coordinate-text'>
          <div class='user-dot-label' style='top:-6px'>${savedDot.label}</div>
          <div class='dot-coordinates'>${savedDot.coordinates}</div>
        </div>
      `;
            editDeleteMenu(dot);
            dot.addEventListener("contextmenu", mouseContextmenu);
            dot.addEventListener('mousedown', mouseDown);
            dot.addEventListener('mouseup', mouseUp);
            window.addEventListener('mousemove', mouseMove, false);
            // @ts-ignore
            event.target.appendChild(dot);
        }
        else {
            log('isOnADot:branch');
        }
        tpf.skipGraphClick = false;
    }
    else {
        log('isClickOutsideGraph:branch');
        log(graphScaledCoords);
    }
}
;
function mouseContextmenu(event) {
    log('dot.contextmenu');
    event.preventDefault();
    removeAnyExistingElementsWithClassName('edit-menu');
    editDeleteMenu(event.target);
    tpf.currentDot = null;
}
function isClickInsideGraph(graphScaledCoords) {
    return graphScaledCoords.x >= -5 && graphScaledCoords.x <= 5 && graphScaledCoords.y >= -5 && graphScaledCoords.y <= 5;
}
function getGraphScaledCoordinates(graphRawCoords) {
    var x = Math.round((graphRawCoords.x - tpf.xAxisWidth / 2) / (tpf.xAxisWidth / 10) * 10) / 10;
    var y = Math.round((tpf.yAxisHeight / 2 - graphRawCoords.y) / (tpf.yAxisHeight / 10) * 10) / 10;
    return { x, y };
}
function getGraphRawCoordinates(event) {
    //@ts-ignore
    var rect = event.target.getBoundingClientRect();
    var x = event.clientX - rect.left;
    var y = event.clientY - rect.top;
    return { x, y };
}
function addDot() {
    const dot = document.createElement("div");
    dot.classList.add("dot-container");
    return dot;
}
